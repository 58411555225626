var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"offcanvas offcanvas-end offcanvas-end--filters d-flex",class:{ show: _vm.visible },style:({ visibility: _vm.visible ? 'visible' : 'hidden' }),attrs:{"id":"offcanvasChapters","tabindex":"-1","aria-labelledby":"offcanvasFiltersLabel"}},[_c('div',{staticClass:"offcanvas-header"},[_vm._m(0),_c('button',{staticClass:"btn-close text-reset",attrs:{"type":"button","data-bs-dismiss":"offcanvas","aria-label":"Close"},on:{"click":_vm.closeOffcanva}})]),_c('div',{staticClass:"offcanvas-body"},[_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("Mail template")]),_c('v-select',{attrs:{"label":"title","clearable":false,"options":_vm.data,"get-option-key":function (option) { return option.id; },"reduce":function (e) { return e.id; }},model:{value:(_vm.mail.mail_template_id),callback:function ($$v) {_vm.$set(_vm.mail, "mail_template_id", $$v)},expression:"mail.mail_template_id"}})],1),_c('hr'),_vm._m(1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("User roles")]),_c('v-select',{attrs:{"label":"name","clearable":false,"options":_vm.roles,"get-option-key":function (option) { return option.id; },"reduce":function (e) { return e.id; }},model:{value:(_vm.mail.role_id),callback:function ($$v) {_vm.$set(_vm.mail, "role_id", $$v)},expression:"mail.role_id"}})],1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("Active")]),_c('v-select',{attrs:{"label":"name","clearable":false,"options":[
            {name: 'All', value: null},
            {name: 'Yes', value: true},
            {name: 'No', value: false} ],"get-option-key":function (option) { return option.name; },"reduce":function (e) { return e.value; }},model:{value:(_vm.mail.active),callback:function ($$v) {_vm.$set(_vm.mail, "active", $$v)},expression:"mail.active"}})],1),_vm._m(2),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("Memoir year")]),_c('v-select',{attrs:{"label":"name","options":[
            { name: '2028', value: 2028 },
            { name: '2027', value: 2027 },
            { name: '2026', value: 2026 },
            { name: '2025', value: 2025 },
            { name: '2024', value: 2024 },
            { name: '2023', value: 2023 } ],"get-option-key":function (option) { return option.name; },"reduce":function (e) { return e.value; }},model:{value:(_vm.mail.memoir),callback:function ($$v) {_vm.$set(_vm.mail, "memoir", $$v)},expression:"mail.memoir"}})],1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("Profile status")]),_c('v-select',{attrs:{"label":"name","options":_vm.memoirStatuses,"get-option-key":function (option) { return option.id; },"reduce":function (e) { return e.id; }},model:{value:(_vm.mail.memoir_profile_status),callback:function ($$v) {_vm.$set(_vm.mail, "memoir_profile_status", $$v)},expression:"mail.memoir_profile_status"}})],1),_c('div',{staticClass:"mb-1"},[_c('label',{staticClass:"form-label",attrs:{"for":""}},[_vm._v("Highlights status")]),_c('v-select',{attrs:{"label":"name","options":_vm.memoirStatuses,"get-option-key":function (option) { return option.id; },"reduce":function (e) { return e.id; }},model:{value:(_vm.mail.memoir_highlights_status),callback:function ($$v) {_vm.$set(_vm.mail, "memoir_highlights_status", $$v)},expression:"mail.memoir_highlights_status"}})],1)]),_c('div',{staticClass:"offcanvas-footer mt-auto"},[_c('button',{staticClass:"btn btn-dark mb-1 d-grid w-100",attrs:{"type":"button"},on:{"click":_vm.send}},[_vm._v(" Send ")])])]),(_vm.visible)?_c('div',{staticClass:"offcanvas-backdrop fade",class:{ show: _vm.visible },on:{"click":_vm.closeOffcanva}}):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"offcanvas-title",attrs:{"id":"offcanvasResearchLineLabel"}},[_c('span',{staticClass:"btn btn-icon btn-icon rounded-circle btn-flat-secondary bg-light-secondary me-2"},[_c('i',{attrs:{"data-feather":"settings"}})]),_vm._v(" Send mail template ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',[_c('strong',[_vm._v("Users")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',[_c('strong',[_vm._v("Memoir")])])}]

export { render, staticRenderFns }